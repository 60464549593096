
import { defineComponent, PropType } from 'vue';
import SecondaryNav from '@/components/common/secondary-nav/index.vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import initData from '@/views/pm/old-community/resident-auth/init-data';
import { ResidentAuthType } from '@/views/pm/old-community/resident-auth/all.type';
import Face from '@/views/pm/old-community/resident-auth/module/face.vue';
import RfCard from '@/views/pm/old-community/resident-auth/module/rf-card.vue';
import Pin from '@/views/pm/old-community/resident-auth/module/pin.vue';

export default defineComponent({
    components: {
        SecondaryNav,
        PropertyBreadCrumb,
        Face,
        RfCard,
        Pin
    },
    props: {
        type: {
            type: String as PropType<ResidentAuthType>,
            default: 'pin'
        }
    },
    setup(props) {
        const propsType = props.type;
        const {
            breadHeaders,
            navs,
            activeNavItem,
            changePath
        } = initData(propsType);

        return {
            breadHeaders,
            navs,
            activeNavItem,
            changePath
        };
    }
});
