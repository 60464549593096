import { computed, Ref, ref } from 'vue';
import { getSearch, ListHeaderItem, RequestData } from '@/components/common/list';
import { community } from '@/data';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';

const headers: Array<ListHeaderItem> = [
    {
        label: WordList.DeviceDetailDetailUnitName,
        name: 'UnitName'
    }, {
        label: WordList.RDeviceSearchLabelRoomName,
        name: 'RoomName'
    }, {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.TmpKeyListTanleKey,
        name: 'Code'
    }, {
        label: WordList.ProperAllTextRepeats,
        name: 'Repeats'
    }, {
        label: WordList.TabelUpdateBoxDevice,
        name: 'DevicesText'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];

const initPin = () => {
    // list请求借口及请求参数
    const requestData: Ref<RequestData> = ref({
        url: 'v3/web/community/key/getUserPinListForOld',
        param: getSearch({
            Build: 'all',
            Room: 'all',
            Key: ''
        })
    });

    // list搜索查询处理
    const updateList = ref(0);
    const saveParamInPath = ref(false);
    const searchList = () => {
        updateList.value += 1;
        saveParamInPath.value = true;
    };

    // pin是否加密
    const isEncryptPin = computed(() => community.IsEncryptPin.value);

    const add = () => {
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldSetResidentAuth}?type=pin&opera=add`);
    };

    const edit = (val: object) => {
        localStorage.setItem('modifyKey', '1');
        localStorage.setItem('propertyKeyDetail', JSON.stringify(val));
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldSetResidentAuth}?type=pin&opera=edit`);
    };

    return {
        requestData,
        updateList,
        saveParamInPath,
        searchList,
        isEncryptPin,
        headers,
        add,
        edit
    };
};
export default initPin;
