import { computed, ref } from 'vue';
import { propertyBaseRouter } from '@/router/data';
import router from '@/router';
import { ResidentAuthType } from '@/views/pm/old-community/resident-auth/all.type';

// 导航
const breadHeaders = [{ label: WordList.ProperAllTextResidentAuth }];

// 标签卡数据
const navs = [
    {
        label: WordList.ProperAllTextPIN,
        key: 'pin',
        path: `/${propertyBaseRouter}/oldResidentAuth?type=pin`
    }, {
        label: WordList.DeliveryRfCard,
        key: 'rfCard',
        path: `/${propertyBaseRouter}/oldResidentAuth?type=rfCard`
    }, {
        label: WordList.ProperAllTextPhotos,
        key: 'face',
        path: `/${propertyBaseRouter}/oldResidentAuth?type=face`
    }
];
const initData = (
    type: ResidentAuthType
) => {
    // 激活的标签
    const activeNavItem = computed(() => type);

    // 标签切换操作
    const changePath = (key: string, path: string) => {
        router.push(path);
    };

    return {
        breadHeaders,
        navs,
        activeNavItem,
        changePath
    };
};
export default initData;