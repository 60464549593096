
import { defineComponent, watch } from 'vue';
import initPin from '@/views/pm/old-community/resident-auth/module/init-pin';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import {
    listPagination, ListActionItem
} from '@/components/common/list';
import AddButton from '@/components/common/add-button/index.vue';
import remove from '@/methods/remove-func';
import { communityForPmApi } from '@/api';

export default defineComponent({
    components: {
        listPagination,
        AddButton
    },
    setup() {
        const {
            requestData,
            updateList,
            saveParamInPath,
            searchList,
            isEncryptPin,
            headers,
            add,
            edit
        } = initPin();
        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();

        // 监听社区选择楼栋，动态改变apt
        watch(() => requestData.value.param.Build, (buildId) => {
            requestData.value.param.Room = 'all';
            setRoomOption(String(buildId));
        });

        // 6.5.1 需求：无归属卡处理
        const cardAction: ListActionItem[] = [{
            type: 'edit2',
            event: 'edit',
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify,
            showCondition(val: { IsShowAction: number }) {
                return val.IsShowAction === 1;
            }
        }, {
            type: 'delete2',
            event(data: { ID: string }) {
                remove(WordList.TabelDeleteText, () => {
                    communityForPmApi.delprprikey({
                        ID: data.ID
                    }, () => {
                        updateList.value += 1;
                    });
                });
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete
        }];

        return {
            requestData,
            buildOptions,
            aptOptions,
            updateList,
            saveParamInPath,
            searchList,
            isEncryptPin,
            headers,
            add,
            edit,
            cardAction
        };
    }
});
